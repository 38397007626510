/**
 * Create a filter. The GH data store will be retained in the picker, if that's needed externally.
 */
export function makeTeamAdminsFilter() {
	if (!wp?.i18n) {
		throw new Error('WordPress is not loaded, or could not get the i18n functions.');
	}

	if (!Groundhogg?.filters?.functions) {
		throw new Error('Groundhogg is not loaded, or could not get the filters functions.');
	}

	const {__} = wp.i18n

	// Get the store creator from Groundhogg.
	const {createStore} = Groundhogg;

	if (!createStore) {
		throw new Error('Groundhogg is not loaded, or could not get the store creator.');
	}

	return {
		view({...props}) {
			return __('Group admins', 'ams-common');
		},
	};
}
