import {whenReady} from "./shared/browser-events.js";
import {setupActivityRenderers as setupGroundhoggActivityRenderer} from "./admin/groundhogg/activity-rendering.js";
import {setupReportSelectors as setupAmsReportSelectors} from "./admin/reporting/report-selector.js";
import {setupLedgerRebuildButtons} from "./admin/ledger/ledger-rebuild.js";
import {setupContactFilters} from "./admin/groundhogg/contact-filters.js";
import {makeWoocommerceProductSkuFieldRequired} from './admin/woocommerce/make-woocommerce-product-sku-field-required.js';
import {setupMembershipPlanPreview} from "./admin/memberships/membership-plan-preview.js";
import {setupGroupMembershipForms} from "./admin/memberships/group-membership-forms.js";
import {highlightMembershipMenu} from "./admin/memberships/group-membership-menu.js";
import {setupIndividualMembershipForm} from "./admin/memberships/individual-membership-manager.js";
import {initiateCustomContactTabs, createContactTab} from "./admin/groundhogg/tabs/contact-tabs.js";
import {contactEventTab} from "./admin/groundhogg/tabs/contact-event-tab.js";
import {enableBulkActionsForMemberReportTables} from "./admin/reporting/report-export.js";

whenReady()
	.then(() => {
		setupGroundhoggActivityRenderer();
		setupAmsReportSelectors();
		setupLedgerRebuildButtons()
		setupContactFilters();
		setupMembershipPlanPreview();
		makeWoocommerceProductSkuFieldRequired();
		setupGroupMembershipForms();
		highlightMembershipMenu();
		setupIndividualMembershipForm();
		enableBulkActionsForMemberReportTables();
		initiateCustomContactTabs()
			.then(() => {
				// Register tabs...
				createContactTab(contactEventTab());
			});
	});


