import {makeEventsWithAttendeesFilter} from "./contact-filters/make-events-with-attendees-filter.js";
import {makePurchaseWithProductTaxonomyFilter} from "./contact-filters/make-purchase-with-product-taxonomy-filter.js";
import {makeMembershipTypesFilter} from "./contact-filters/make-membership-types-filter.js";
import {makeTeamNamesFilter} from "./contact-filters/make-team-names-filter.js";
import {makeTeamAdminsFilter} from "./contact-filters/make-team-admins-filter.js";

export function setupContactFilters() {

	if (!Groundhogg?.filters?.functions) {
		throw new Error('Groundhogg is not loaded, or could not get the filters functions.');
	}

	if (!wp?.i18n) {
		throw new Error('WordPress is not loaded, or could not get the i18n functions.');
	}

	const {
		registerFilter,
		registerFilterGroup,
	} = Groundhogg.filters.functions;

	// Get some formatting/localisation functions from WP.
	const {__} = wp.i18n

	// Register the filter group for AMS.
	registerFilterGroup('ams-common', __('AMS', 'ams-common'));

	registerFilter(
		'ams_events_with_attendees',
		'ams-common',
		__('Event Attendees (AMS)', 'ams-common'),
		makeEventsWithAttendeesFilter()
	);

	registerFilter(
		'ams_purchase_with_product_taxonomy',
		'ams-common',
		// @todo Find a better name, and/or split this out into 2 filters which deal with each type of tax (cat/tag) independently.
		__('Purchase with Product Taxonomies (AMS)', 'ams-common'),
		makePurchaseWithProductTaxonomyFilter()
	);

	registerFilter(
		'ams_membership_types',
		'ams-common',
		__('Membership Types (AMS)', 'ams-common'),
		makeMembershipTypesFilter()
	);

	registerFilter(
		'ams_team_names',
		'ams-common',
		__('Group Membership Name (AMS)', 'ams-common'),
		makeTeamNamesFilter()
	);

	registerFilter(
		'ams_team_admins',
		'ams-common',
		__('Group Admins (AMS)', 'ams-common'),
		makeTeamAdminsFilter()
	);
}
