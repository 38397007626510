/**
 * @typedef {Object} TabOptions
 * @property {string} slug The slug, used in the link and tab mapping.
 * @property {string} title The title. Used in the link.
 * @property {undefined|function|string} render The render callback - this should produce text or elements.
 * @property {undefined|boolean} supported If the tab is supported (at time of creation).
 */

/**
 * @typedef {Object} Tab
 * @property {string} slug The slug, used in the link and tab mapping.
 * @property {string} title The title. Used in the link.
 * @property {function|string} render The render callback - this should produce text or elements.
 * @property {HTMLElement} renderContainer The dom node which persists, and is injected into the DOM when displayed.
 */

/**
 * The container for the contact tabs.
 * @type {null|HTMLElement}
 */
let $otherContactStuffContainer = null;

/**
 * Holds all registered tabs.
 * @type {Object.<string, Tab>}
 */
const tabs = {};


/**
 * Initiate custom contact tabs... Returns a promise which resolves, so that tabs/etc can be registered if/when
 * all the tab stuff is present and setup.
 *
 * @return {Promise<unknown>}
 */
export function initiateCustomContactTabs() {
	return new Promise((resolve, reject) => {
		const currentQuery = new URLSearchParams(window.location.search);

		// Definitely not a contact page, bail.
		if (!currentQuery.has('page') || !currentQuery.has('action') || !currentQuery.has('contact')) {
			reject('Not a contact page, missing page|action|contact.');
			return;
		}

		// Not a contact page, bail.
		if (currentQuery.get('page') !== 'gh_contacts') {
			reject('Not a contact page, page is incorrect value.');
			return;
		}

		// The primary section of the contacts UI is build and rendered with JS, and there's no way to extend/modify that 🤦‍♂️...
		// Instead, we need to observe the dom for the elements we want, and _then_ act...
		$otherContactStuffContainer = document.querySelector('#other-contact-stuff');

		if (!$otherContactStuffContainer) {
			reject('Appears to be a contact page, however the "other contact stuff" container does not exist...');
			return;
		}

		const observer = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				if (mutation.type === 'childList' && mutation.target.id === 'other-contact-stuff') {
					const $tabContainer = mutation.target.querySelector('.nav-tab-wrapper');

					// If a nav-tab-wrapper was added, do the rest of setup!
					if (!$tabContainer) {
						return;
					}

					// When the tab container is finally present in the page, remove this observer and
					// setup our tab behaviour.
					observer.disconnect();
					setupTabManagement($otherContactStuffContainer);
				}
			});
		});

		observer.observe($otherContactStuffContainer, {childList: true});

		resolve($otherContactStuffContainer);
	});
}


/**
 * Register a tab as defined with makeContactTab()
 *
 * @param {TabOptions} tab
 */
export function createContactTab(tab) {
	if (!tab.slug || !tab.title || !tab.render) {
		throw 'Tab needs slug, title, and render';
	}

	if (typeof tab.supported === 'undefined') {
		tab.supported = true;
	}

	if (!tab.supported) {
		console.warn('Tab is not support right now. Perhaps dependencies not met?', {tab})
		return;
	}

	const renderContainer = document.createElement('div');
	renderContainer.dataset.amsTabContainer = 'true';
	[
		'gh-panel',
		'top-left-square',
		'ams-contact-tab-content',
		`ams-contact-tab-content--${tab.slug}`,
	].forEach(className => renderContainer.classList.add(className));

	tabs[tab.slug] = {...tab, renderContainer};

	if ($otherContactStuffContainer) {
		rebuildTabs($otherContactStuffContainer);
	}

	return tabs[tab.slug];
}

/**
 * We need to observe and manage the state of our own tabs on top of GH, as GH
 * doesn't allow for any custom tabs...
 * @param {HTMLElement} $container
 */
function setupTabManagement($container) {
	// Build tabs the first time.
	const $tabContainer = $container.querySelector('#secondary-tabs');

	if ($tabContainer) {
		rebuildTabs($tabContainer);
	}

	$container.addEventListener('click', event => {
		if (!event.target.matches('[data-ams-slug]')) {
			return;
		}

		activateTabFromLink(event.target);
	});

	// Because of how GH rebuilds the tab bar _EVERY_ time the tab/content is changed, we need to observe for that,
	// and then re-add our tabs and all that malarky when required...

	const observer = new MutationObserver((mutations) => {
		let tabsWereRebuilt = false;
		mutations.forEach((mutation) => {
			// We've already determined to rebuild tabs, skip...
			if (tabsWereRebuilt) {
				return;
			}

			// Find the tab container.
			const $tabsContainer = Array.from(mutation.addedNodes).filter(
				node => node?.id === 'secondary-tabs'
			);

			// The tab container wasn't an added node, bail...
			if ($tabsContainer.length === 0) {
				return;
			}

			rebuildTabs($tabsContainer[0]);
			tabsWereRebuilt = true;
		});
	});

	observer.observe($container, {childList: true, attributes: false, subtree: false});
}

/**
 * Given a tab container, rebuild all our tabs in that container.
 *
 * @param {HTMLElement} $tabContainer The container to put our tabs in.
 */
function rebuildTabs($tabContainer) {
	const $navTabWrapper = $tabContainer.querySelector('.nav-tab-wrapper');

	if (!$navTabWrapper) {
		console.warn('Attempted to rebuild tabs, but the container did not have the tab wrapper', {$tabContainer})
		return;
	}

	// Remove existing AMS tabs, just in case...
	$tabContainer.querySelectorAll('[data-ams-slug]').forEach($node => $node.remove());

	Object.entries(tabs).forEach(tabSpec => {
		const tab = tabSpec[1];

		const $newTab = document.createElement('a');
		$newTab.classList.add('nav-tab');
		$newTab.dataset.amsSlug = tab.slug;
		$newTab.setAttribute('href', '#');
		$newTab.innerText = tab.title;

		$navTabWrapper.append($newTab);
	});
}

/**
 * Given an anchor/link element, try to determine if there is a tab to be activated and rendered. Probably bound to a
 * click event.
 *
 * @param {HTMLAnchorElement} $tabLink The link which should activate a tab.
 */
function activateTabFromLink($tabLink) {

	if (!$tabLink || !$tabLink.dataset.amsSlug) {
		console.error('Invalid tab link provided to activate a tab...', {$tabLink});
		return;
	}

	const tab = tabs[$tabLink.dataset.amsSlug || null]

	if (!tab) {
		console.error('No tab to activate?', {$tabLink});
		return;
	}

	const $siblingTabs = $tabLink.parentElement.querySelectorAll('.nav-tab');

	// Select our tab...
	$siblingTabs.forEach($tab => $tab.classList.remove('nav-tab-active'));
	$tabLink.classList.add('nav-tab-active');

	// Remove other content, which is a sibling to the container of tabs, and add ours...
	while ($tabLink.parentElement.nextElementSibling) {
		$tabLink.parentElement.nextElementSibling.remove();
	}

	// Clear and render the tab contents.
	while (tab.renderContainer.hasChildNodes()) {
		tab.renderContainer.firstChild.remove();
	}

	const contents = tab.render();

	if (typeof contents === 'string') {
		tab.renderContainer.innerHTML = contents;
	} else {
		tab.renderContainer.append(contents);
	}

	$tabLink.parentElement.parentElement.append(tab.renderContainer);
}


/**
 * Generate an error result.
 */
export function getErrorMessageElement(message) {
	const $container = document.createElement('div');

	[
		'ams-contact-tab-message',
		'ams-contact-tab-message--error'
	].forEach(className => $container.classList.add(className));

	$container.innerHTML = message;

	return $container;
}

/**
 * Empty the provided element of all children.
 * @param {HTMLElement} $element The container to empty.
 */
export function emptyElement($element) {
	if (!$element) {
		return;
	}

	while ($element.hasChildNodes()) {
		$element.removeChild($element.childNodes[0]);
	}
}

/**
 * Given a key (in dot notation), discover a value from an object containing data. Hierarchical objects are supported.
 *
 * @param {string} key Key in dot notation, eg "user.address.line_1"
 * @param {object} data The data containing values.
 * @return {undefined|*}
 */
export function discoverValueFromObject(key, data) {

	if (typeof data !== 'object') {
		console.warn('Tried to get data from a non-object', {key, data})
		return undefined;
	}

	if (key.indexOf('.') >= 0) {
		const firstKey = key.split('.', 1);
		const remainingKey = key.replace(`${firstKey}.`, '', key);
		const childData = data[firstKey] || undefined;

		if (typeof childData === 'undefined') {
			return childData;
		}

		return discoverValueFromObject(remainingKey, childData);
	}

	return data[key];
}
