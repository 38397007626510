import {discoverValueFromObject, emptyElement, getErrorMessageElement} from "./contact-tabs.js";

let $eventAttendeeTemplate = null;

let $eventsDisplayContainer = null;
let events = {
	upcoming: [],
	past: [],
};

/**
 * Get (and create if not existing yet) the element for which the events info should be appended.
 *
 * @return {HTMLElement}
 */
function getEventsContainer() {
	if (!$eventsDisplayContainer) {
		$eventsDisplayContainer = document.createElement('div');

		const $message = document.createElement('em');
		$message.classList.add('loading-message');
		$message.innerText = 'Loading events...';

		$eventsDisplayContainer.append($message);
	}

	return $eventsDisplayContainer;
}

/**
 * Given some attendee data, create some new DOM elements from a <template>, and for any [data-ams-attendee-prop] attributes
 * replace those with values discovered from the contact data.
 *
 * @param {Object} attendeeData The incoming data.
 *
 * @return {HTMLElement} The new attendee row.
 */
function getContactAttendeeElement(attendeeData) {

	const $result = $eventAttendeeTemplate.content
		.cloneNode(true)
		.firstElementChild;

	// Loop all props, so we can see what prop the element wants for its content or attribute.
	$result.querySelectorAll('[data-ams-attendee-prop]').forEach($el => {
		const property = $el.dataset.amsAttendeeProp;

		// If the html attribute is set, write the value into an attribute, rather than the inner HTML. Useful for things
		// like links, etc.
		const targetHtmlAttribute = $el.dataset.amsAttendeePropHtmlAttribute;

		const value = discoverValueFromObject(property, attendeeData);

		if (!value) {
			return;
		}

		// Set the content, or attribute.
		if (targetHtmlAttribute) {
			$el.setAttribute(targetHtmlAttribute, value);
		} else {
			$el.innerHTML = value;
		}

	});

	return $result;
}


/**
 * Load the contact's events - this can only be called once, the results will be cached and added to the parent
 * container only once to save on loading/etc.
 */
function maybeLoadContactEvents() {
	// Prevent reruns...
	if (maybeLoadContactEvents.__alreadyCalled) {
		console.info('load contact events has already been done...')
		return;
	}

	maybeLoadContactEvents.__alreadyCalled = true;

	const nonce = window['groundhogg_nonces']?._wprest;

	if (!nonce) {
		alert('Sorry, there was a problem. Please reload the page and try again.');
		console.error('There was no Groundhogg nonce present to make the AJAX request!');
		return;
	}

	const endpoint = window['amsGroundhoggContactTabs']?.routes?.contact_events;

	if (!endpoint) {
		alert('Sorry, there was a problem determining contact events. Please reload the page and try again.');
		console.error('Could not determine contact events endpoint URL...');
		return;
	}

	const $listContainerTemplate = document.querySelector('#ams-contact-event-attendee-list');

	if (!$listContainerTemplate) {
		alert('There was a problem loading contact events. Please reload the page and try again.');
		console.error('Could not find the event list container template.');
		return;
	}

	const $listContainer = $listContainerTemplate.content.cloneNode(true).firstElementChild;

	if (!$listContainer) {
		alert('There was a problem loading contact events. Please reload the page and try again.');
		console.error('Could not determine the event list container elements to append to.');
		return;
	}

	const $upcomingContainer = $listContainer.querySelector('.ams-contact-attended-events__upcoming');
	const $pastContainer = $listContainer.querySelector('.ams-contact-attended-events__past');

	const currentContactPageQuery = new URLSearchParams(window.location.search);
	const contactId = parseInt(currentContactPageQuery.get('contact') || 0);

	if (!contactId) {
		alert('Could not determine a contact to fetch events for. Please reload and try again.');
		console.error('No contact ID was found in the current URL?');
		return;
	}

	const $targetContainer = getEventsContainer();

	// Make sure the contact's ID is included in the request.
	const url = endpoint.replace(/\?.*/g, '') + `?contact_id=${contactId}`;

	const args = {
		url,
		beforeSend: xhr => {
			/* global groundhogg_nonces */
			xhr.setRequestHeader('X-WP-Nonce', nonce);
		},
		dataType: 'json',
		success: result => {
			emptyElement($targetContainer);

			if (!Array.isArray(result)) {
				$targetContainer.append(getErrorMessageElement('Unexpected data was provided. Please reload and try again.'));
				return;
			}


			result.forEach(attendeeInfo => {
				const $attendeeRow = getContactAttendeeElement(attendeeInfo);
				if (attendeeInfo.attendee.is_past) {
					$pastContainer.append($attendeeRow);
				} else {
					$upcomingContainer.append($attendeeRow);
				}
			});

			$targetContainer.append($listContainer);

		},
		error: error => {
			emptyElement($targetContainer);
			$targetContainer.append(getErrorMessageElement('There was an error getting the contact events. Please reload and try again.'));
		},
	};

	jQuery.ajax(args);
}

/**
 * Return the definition for the contact event tab.
 *
 * @return {TabOptions}
 */
export function contactEventTab() {

	const tabBase = {
		slug: 'ams-events',
		title: 'Events',
	};

	let supported = true;

	if (!window['amsGroundhoggContactTabs']?.routes?.contact_events) {
		console.warn('Could not determine the contact events route...');
		supported = false;
	}

	$eventAttendeeTemplate = document.querySelector(`#ams-contact-event-attendee`);

	if (!$eventAttendeeTemplate || $eventAttendeeTemplate.tagName !== 'TEMPLATE') {
		console.warn('Could not discover the contact attendee template.');
		supported = false;
	}

	if (!supported) {
		return {...tabBase, supported: false};
	}

	return {
		...tabBase,
		render: () => {
			maybeLoadContactEvents();
			return getEventsContainer();
		},
	};
}
