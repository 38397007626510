export function enableBulkActionsForMemberReportTables() {
// Select the target table body
	const tableBody = document.querySelector('#js-membership-reporting-form .wp-list-table tbody');

	if (tableBody) {
		// Create a new table row
		const newRow = document.createElement('tr');

		// Set the inner HTML of the new row
		newRow.innerHTML = `
        <th scope="row" class="check-column" style="display:none">
            <input type="checkbox" name="post" value="" checked>
        </th>
    `;

		// Append the new row to the table body
		tableBody.appendChild(newRow);
	}
}
